import React from 'react';

const NotFoundPage = () => {
  return (
    <div className='not-found'>
      <h1>Not Found</h1>
    </div>
  );
};

export default NotFoundPage;
